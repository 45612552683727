import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { User } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatPhoneNumber } from '@shared/utils'

interface GeneralSettingsProps {
  user: User
}

export const GeneralSettings: FC<GeneralSettingsProps> = ({ user }) => {
  const { t } = useTranslation()

  const valueCellPhone = user.cell_phone
    ? formatPhoneNumber(user.cell_phone)
    : undefined

  const valuePageSize = user.ui_prefs?.page_size
    ? `${user.ui_prefs?.page_size} ${t('common.pagination-rows')}`
    : undefined

  return (
    <FieldGroupContainer title={t('mfe-user-profile.general-settings')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.name-first')}
          value={user.first_name}
        />
        <FieldGroupViewItem
          title={t('common.name-last')}
          value={user.last_name}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem title={t('common.email')} value={user.email} />
        <FieldGroupViewItem
          title={t('common.phone-number')}
          value={valueCellPhone}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('mfe-user-profile.timezone')}
          value={user.tz}
        />
        <FieldGroupViewItem
          title={t('mfe-user-profile.page-size-results')}
          value={valuePageSize}
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}
