import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { BrowserRouter, Routes } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { CircularProgress, Grid, ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api'
import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  NotificationProvider,
  useEnforceLogin,
} from '@shared/hooks'

import i18n from './i18n'
import { UserProfileInfo, EditUserProfile } from './pages'

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const useStyles = tss.withName('FtpFeatureMfeUserProfile').create({
  loadingContainer: {
    display: 'grid',
    minHeight: '300px',
    placeContent: 'center',
    backgroundColor: '#FFFFFF',
  },
})

const UserProfileApp = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setPortalVersion, setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()

  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const isLoading = !addressablePageManager

  const pages = useMemo(() => {
    const root_url = `${window?.location?.pathname?.split('/userprofile')[0]}`
    return [
      // This first route is just for testing purposes
      new AddressablePage('', <></>, [], '/'),
      new AddressablePage(
        'User Profile',
        <UserProfileInfo />,
        [],
        `${root_url}/userprofile`
      ),
      new AddressablePage(
        'Edit User Profile',
        <EditUserProfile />,
        [],
        `${root_url}/userprofile/edit`
      ),
    ]
  }, [user])

  useEffect(() => {
    setAppBarTitle('', undefined, t('common.profile'))
    setPortalVersion({ version: packageJSON.version })
  }, [])

  useEffect(() => {
    if (!user) return

    setAppBarTitle(
      `${user.first_name || ''} ${user.last_name}`,
      undefined,
      t('common.profile')
    )

    setAddressablePageManager(new AddressablePageManager(pages, user))
  }, [user])

  return (
    <AuthorizationProvider user={user}>
      <ThemeProvider theme={AppTheme}>
        {isLoading && (
          <Grid className={classes.loadingContainer}>
            <CircularProgress data-testid="loading-icon" />
          </Grid>
        )}

        {!isLoading && (
          <Routes>{addressablePageManager.renderAllAddressablePages()}</Routes>
        )}
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

const UserProfile: React.FC = () => (
  <FtpPortalHubCommunicationProvider>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <EnforceLoginProvider redirectToLogin>
          <BrowserRouter>
            <NotificationProvider>
              <UserProfileApp />
            </NotificationProvider>
          </BrowserRouter>
        </EnforceLoginProvider>
      </ErrorBoundary>
    </I18nextProvider>
  </FtpPortalHubCommunicationProvider>
)

export default UserProfile
