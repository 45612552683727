import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { User } from '@shared/api/src'
import {
  PageLayoutContainer,
  PageLayoutContainerMain,
  ButtonBar,
  ButtonBarEnd,
  PageLayoutDivider,
  Button,
  AdvancedSettings,
} from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'

import { GeneralSettings } from '@/components'
import { ChangePassword } from '@/components/change-password/ChangePassword'

export const UserProfileInfo: FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [changePassword, setChangePassword] = useState(false)

  const { user, updateUser } = useEnforceLogin()

  const onEditProfile = () => {
    navigate(`${window.location.pathname}/edit`)
  }

  return (
    <>
      <ChangePassword
        user={user}
        open={changePassword}
        handleClose={() => setChangePassword(false)}
      />
      <ButtonBar>
        <ButtonBarEnd>
          <Button
            label={t('mfe-user-profile.change-password')}
            color="secondary"
            onClick={() => setChangePassword(true)}
            style={{ marginRight: '10px' }}
            guidingId="userprofile-changepassword"
          />
          <Button
            label={t('common.edit')}
            onClick={onEditProfile}
            style={{ marginRight: '10px' }}
            guidingId="userprofile-edit"
          />
        </ButtonBarEnd>
      </ButtonBar>

      <PageLayoutContainer>
        <PageLayoutContainerMain isFullWidth>
          <GeneralSettings user={user} />
          <PageLayoutDivider />
          <AdvancedSettings
            user={user}
            setUser={updateUser}
            guidingId="userprofile-advancedsettings"
          />
        </PageLayoutContainerMain>
      </PageLayoutContainer>
    </>
  )
}
