export const createHashKey = () => {
  const hashIds = []
  const hexDigits = '0123456789abcdef'

  for (let i = 0; i < 24; i++) {
    const num = Math.floor(Math.random() * 0x10)
    hashIds[i] = hexDigits.substring(num, num + 1)
  }

  const num2 = (Number(hashIds[19]) & 0x3) | 0x8
  hashIds[19] = hexDigits.substring(num2, num2 + 1)

  return hashIds.join('')
}
