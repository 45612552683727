import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Close } from '@mui/icons-material'
import { Box, Modal, Typography } from '@mui/material'

import { FeathersError, User, api } from '@shared/api'
import { ActionModal, Button } from '@shared/components'
import { useNotification } from '@shared/hooks'
import { createHashKey } from '@shared/utils'

export enum KeyType {
  API_KEY = 'API_KEY',
  HASH_KEY = 'HASH_KEY',
}

interface GenerateKeyModalProps {
  open: boolean
  keyType: KeyType
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  onClose: (showKeysRecord?: {
    showFullApiKey: boolean
    showFullHashKey: boolean
  }) => void
}

export const GenerateKeyModal: FC<GenerateKeyModalProps> = ({
  open,
  keyType,
  user,
  setUser,
  onClose,
}) => {
  const { t } = useTranslation()

  const { setNotification } = useNotification()

  const keyName = keyType === KeyType.API_KEY ? 'API' : 'Hash'

  const [isLoading, setIsLoading] = useState(false)

  const onGenerateKey = async () => {
    setIsLoading(true)

    try {
      if (keyType === KeyType.API_KEY) {
        const { user_api_key } = await api.service('users').apiKey(user.id)

        setUser((user) => ({
          ...user,
          user_api_key,
        }))
      }

      if (keyType === KeyType.HASH_KEY) {
        const newHashKey = createHashKey()

        await api.service('users').patch(user.id, {
          user_hash_key: newHashKey,
        })

        setUser((user) => ({
          ...user,
          user_hash_key: newHashKey,
        }))
      }

      setIsLoading(false)

      onClose({
        showFullApiKey: keyType === KeyType.API_KEY,
        showFullHashKey: keyType === KeyType.HASH_KEY,
      })

      setNotification({
        type: 'success',
        label: `${keyName} key successfully generated.`,
      })
    } catch (error) {
      // This is handled by the api.on('apiError') event that can be found further below
      // This is just for the app not to break
    }
  }

  useEffect(() => {
    const onAPIError = (_: FeathersError) => {
      setIsLoading(false)

      setNotification({
        type: 'error',
        label: `Failed to update the ${keyName} key. Please Try Again.`,
      })
    }

    api.on('apiError', onAPIError)

    return () => {
      api.off('apiError', onAPIError)
    }
  }, [])

  return (
    <ActionModal
      open={open}
      onClose={() => onClose()}
      title={`${t('common.generate')} ${keyName} ${t('common.key')}`}
      buttons={[
        {
          label: t('common.cancel'),
          color: 'secondary',
          onClick: () => onClose(),
          guidingId: 'generatekey-cancel',
        },
        {
          label: t('common.yes'),
          isLoading,
          onClick: onGenerateKey,
          guidingId: 'generatekey-yes',
        },
      ]}
      guidingId="generatekey"
    >
      <Typography variant="body2" marginBottom="16px">
        {t('common.are-you-sure-you-would-like-to-generate')}{' '}
        {keyType === KeyType.API_KEY ? 'an' : 'a'}{' '}
        <strong>
          {keyName} {t('common.key')}?
        </strong>
      </Typography>

      <Typography variant="body2">
        {t('common.this-action-cannot-be-undone-and-will-override-any-current')}{' '}
        {keyName} {t('common.key')}, {t('common.if-one-exists')}.
      </Typography>
    </ActionModal>
  )
}
