import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Modal, Box, Typography } from '@mui/material'

import { User, api } from '@shared/api'
import {
  InputPassword,
  Button,
  Notification,
  TermsAndConditions,
  PasswordRules,
  ActionModal,
} from '@shared/components'
import { useNotification } from '@shared/hooks'

const useStyles = tss.withName('CreateNewPassword').create(({ theme }) => ({
  modal: {
    minWidth: '700px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginBottom: '24px',
  },
}))

const passwordSchema = yup.object({
  currentPassword: yup.string().required('Enter your current password'),
  newPassword: yup
    .string()
    .required('Enter a new password')
    .min(8, 'Your new password must have at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$#!%*?&]+$/,
      'Your new password must contain at least 1 special character'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
})

type ChangePasswordData = yup.InferType<typeof passwordSchema>

interface ChangePassword {
  user: User
  open: boolean
  handleClose: () => void
}

export const ChangePassword: FC<ChangePassword> = ({
  user,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()

  const [termsCode, setTermsCode] = useState<string>()

  const [serverErrors, setServerErrors] = useState<{
    currentPassword?: string
    newPassword?: string
  }>({})

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<ChangePasswordData>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: yupResolver(passwordSchema),
    mode: 'onBlur',
  })

  const oldPassword = useWatch({ name: 'currentPassword', control })
  const newPassword = useWatch({ name: 'newPassword', control })
  const confirmNewPassword = useWatch({ name: 'confirmNewPassword', control })

  useEffect(() => {
    setServerErrors({})
  }, [newPassword, oldPassword])

  const onSubmit = async () => {
    setServerErrors({})
    try {
      await api
        .service('users')
        .changePassword(user.id, oldPassword, newPassword, termsCode)

      setNotification({
        type: 'success',
        label: 'Password changed successfully',
      })
      reset()
      handleClose()
    } catch (error) {
      const customErrors = error?.data?.error?.meta?.errors
      if (customErrors && Object.keys(customErrors).length > 0) {
        setServerErrors({
          currentPassword: customErrors.old_password,
          newPassword: customErrors.password,
        })
      }
      setNotification({
        type: 'error',
        label: 'Error changing password',
      })
    }
    return
  }

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('mfe-user-profile.change-password')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: () => {
            reset()
            handleClose()
          },
          guidingId: 'changepasword-cancel',
        },
        {
          label: t('mfe-user-profile.change-password'),
          onClick: () => {
            onSubmit()
          },
          disabled: !termsCode || !isValid,
          guidingId: 'changepasword-changepassword',
        },
      ]}
      className={classes.modal}
      isScrollableContent
      guidingId="changepassword"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.content}>
          <Typography>
            {t(
              'mfe-user-profile.make-sure-to-not-reuse-any-of-your-last-four-passwords'
            )}
          </Typography>

          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <InputPassword
                {...field}
                testId="currentpassword-input"
                label={t('mfe-user-profile.current-password')}
                placeholder={t('mfe-user-profile.enter-your-current-password')}
                error={
                  !!errors?.currentPassword || !!serverErrors?.currentPassword
                }
                helperText={
                  errors?.currentPassword?.message ||
                  serverErrors?.currentPassword
                }
                guidingId="changepasword-currentpassword"
              />
            )}
          />

          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <InputPassword
                {...field}
                testId="newpassword-input"
                label={t('mfe-user-profile.new-password')}
                placeholder={t('mfe-user-profile.enter-your-new-password')}
                error={!!errors?.newPassword || !!serverErrors?.newPassword}
                helperText={
                  errors?.newPassword?.message || serverErrors?.newPassword
                }
                guidingId="changepasword-newpassword"
                onBlur={() => {
                  field.onBlur()
                  if (confirmNewPassword) {
                    trigger('confirmNewPassword')
                  }
                }}
              />
            )}
          />

          <PasswordRules password={newPassword} />

          <Controller
            name="confirmNewPassword"
            control={control}
            render={({ field }) => (
              <InputPassword
                {...field}
                testId="confirmnewpassword-input"
                label={t('mfe-user-profile.confirm-new-password')}
                placeholder={t('mfe-user-profile.re-enter-your-new-password')}
                error={!!errors?.confirmNewPassword}
                helperText={errors?.confirmNewPassword?.message}
                guidingId="changepasword-confirmnewpassword"
                onBlur={() => {
                  field.onBlur()
                  if (newPassword) {
                    trigger('newPassword')
                  }
                }}
              />
            )}
          />
        </Box>

        <TermsAndConditions
          handleAcceptTermsConditions={(terms) => {
            setTermsCode(terms?.code?.toString())
          }}
        />
      </form>
    </ActionModal>
  )
}
