export const timezoneOptions = [
  {
    value: 'Pacific/Honolulu',
    label: ' Pacific/Honolulu  (Hawaiian GMT)',
  },
  {
    value: 'America/Anchorage',
    label: ' America/Anchorage  (Alaskan GMT)',
  },
  {
    value: 'America/Los_Angeles',
    label: 'America/Los Angeles  (Pacific GMT)',
  },
  {
    value: 'America/Denver',
    label: 'America/Denver  (Mountain GMT)',
  },
  {
    value: 'America/Phoenix',
    label: 'America/Phoenix  (Mountain GMT)',
  },
  {
    value: 'America/Chicago',
    label: 'America/Chicago  (Central GMT)',
  },
  {
    value: 'America/Detroit',
    label: 'America/Detroit  (Eastern GMT)',
  },
  {
    value: 'America/New_York',
    label: 'America/New York  (Eastern GMT)',
  },
]
